import React from "react";
import { Link } from "react-router-dom";
import TeamDetailsProgressBar from "../../components/Elements/Progress/TeamDetailsProgressBar";
// Breadcrumbs Bg
import teamImg1 from "../../assets/img/team/av.png";
import teamImgf from "../../assets/img/team/avf.png";

import { portalUrl } from "../../constants/defaultValues";
import Publications from "../home-8/PublicationSection";

const TeamSingleMain = ({ member }) => {
  return (
    member && (
      <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70 rtl">
        <div className="container">
          <div className="row clearfix">
            <div className="image-column col-lg-5 md-mb-50">
              <div className="inner-column mb-50 md-mb-0">
                <div className="image">
                  <img
                    src={
                      member.image
                        ? `${portalUrl}${member.image}`
                        : member.sex === "انثى"
                        ? teamImgf
                        : teamImg1
                    }
                    alt="images"
                  />
                </div>
                <div className="team-content text-center">
                  <h3>{member.name}</h3>
                  <div className="text">الدرجة العلمية : {member.AcDegree}</div>
                  <ul className="personal-info">
                    <li className="email">
                      <span>
                        <i className="glyph-icon flaticon-email"> </i>{" "}
                      </span>
                      <Link to="#">{member.email}</Link>
                    </li>
                    {member.phone && (
                      <li className="phone">
                        <span>
                          <i className="glyph-icon flaticon-call"></i>
                        </span>
                        <Link to="#">{member.phone}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="social-box">
                  {member.researchGate && member.researchGate.length > 1 && (
                    <a
                      href={member.researchGate}
                      rel="noreferrer"
                      target="_blank"
                    >
                      R<sup>G</sup>
                    </a>
                  )}
                  {member.CV && (
                    <a
                      href={`${portalUrl}${member.CV}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      CV
                    </a>
                  )}
                  {member.googleSchooler && member.googleSchooler.length > 1 && (
                    <a
                      href={member.googleSchooler}
                      rel="noreferrer"
                      target="_blank"
                    >
                      G<sup>SC</sup>
                    </a>
                  )}
                  {member.orcid && member.orcid.length > 1 &&(
                    <a href={member.orcid} rel="noreferrer" target="_blank">
                      orcid
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
              <div className="inner-column">
                <h2>{member.name}</h2>
                <h4>{member.adjective}</h4>
                {/* <ul className="student-list">
                <li>23,564 Total Students</li>
                <li>
                  <span className="theme_color">4.5</span>{" "}
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span> (1254 Rating)
                </li>
                <li>256 Reviews</li>
              </ul> */}
                <h5>نبذة عن المسار الاكاديمي / Career Overview</h5>
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: member.careerOverview }}
                ></p>
                <div className="team-skill mb-50">
                  <h3 className="skill-title">المهام / Role</h3>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{ __html: member.role }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="content-part">
                <h3 className="title">المجالات البحثية / Research Areas</h3>
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: member.researchAreas,
                  }}
                ></p>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="content-part">
                <h3 className="title">
                  الانشطة العملية - المهنية / Exrternal Activities
                </h3>
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: member.externalActivities,
                  }}
                ></p>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="content-part">
                  <h3 className="title"> المنشورات / Publications</h3>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{
                      __html: member.publications,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TeamSingleMain;
