/* FACULTIES */
export const FACULTIES_LIST_GET_LIST = "FACULTIES_LIST_GET_LIST";
export const FACULTIES_LIST_GET_LIST_SUCCESS =
  "FACULTIES_LIST_GET_LIST_SUCCESS";
export const FACULTIES_LIST_GET_LIST_ERROR = "FACULTIES_LIST_GET_LIST_ERROR";
export const FACULTIES_LIST_GET_LIST_SEARCH = "FACULTIES_LIST_GET_LIST_SEARCH";
export const FACULTIES_LIST_GET_ONE = "FACULTIES_LIST_GET_ONE";
export const FACULTIES_LIST_GET_ONE_SUCCESS = "FACULTIES_LIST_GET_ONE_SUCCESS";
export const FACULTIES_LIST_GET_ONE_ERROR = "FACULTIES_LIST_GET_ONE_ERROR";

/* members */

export const MEMBERS_LIST_GET_LIST_BY_FACULTY =
  "MEMBERS_LIST_GET_LIST_BY_FACULTY";

export const MEMBERS_LIST_GET_LIST = "MEMBERS_LIST_GET_LIST";
export const MEMBERS_LIST_GET_LIST_SUCCESS = "MEMBERS_LIST_GET_LIST_SUCCESS";
export const MEMBERS_LIST_GET_LIST_ERROR = "MEMBERS_LIST_GET_LIST_ERROR";
export const MEMBERS_LIST_GET_ONE = "MEMBERS_LIST_GET_ONE";
export const MEMBERS_LIST_GET_ONE_SUCCESS = "MEMBERS_LIST_GET_ONE_SUCCESS";
export const MEMBERS_LIST_GET_ONE_ERROR = "MEMBERS_LIST_GET_ONE_ERROR";

export const MEMBERS_LIST_GET_ALL_LIST = "MEMBERS_LIST_GET_ALL_LIST";
export const MEMBERS_LIST_GET_ALL_LIST_SUCCESS = "MEMBERS_LIST_GET_ALL_LIST_SUCCESS";
export const MEMBERS_LIST_GET_ALL_LIST_ERROR = "MEMBERS_LIST_GET_ALL_LIST_ERROR";
export const MEMBERS_LIST_SEARCH = 'MEMBERS_LIST_SEARCH';
/* Speech */
export const SPEECH_GET_LIST = "SPEECH_GET_LIST";
export const SPEECH_GET_LIST_SUCCESS = "SPEECH_GET_LIST_SUCCESS";
export const SPEECH_GET_LIST_ERROR = "SPEECH_GET_LIST_ERROR";

/* Speech */
export const ACADPROGRAM_GET_LIST = "ACADPROGRAM_GET_LIST";
export const ACADPROGRAM_GET_LIST_SUCCESS = "ACADPROGRAM_GET_LIST_SUCCESS";
export const ACADPROGRAM_GET_LIST_ERROR = "ACADPROGRAM_GET_LIST_ERROR";
export const ACADPROGRAM_GET_LIST_BY_TRACKING =
  "ACADPROGRAM_GET_LIST_BY_TRACKING";
export const ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS =
  "ACADPROGRAM_GET_LIST_BY_TRACKING_SUCCESS";
export const ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR =
  "ACADPROGRAM_GET_LIST_BY_TRACKING_ERROR";
export const ACADPROGRAM_GET_ONE = "ACADPROGRAM_GET_ONE";
export const ACADPROGRAM_GET_ONE_SUCCESS = "ACADPROGRAM_GET_ONE_SUCCESS";
export const ACADPROGRAM_GET_ONE_ERROR = "ACADPROGRAM_GET_ONE_ERROR";

/* Articles */
export const ARTICLES_GET_LIST = "ARTICLES_GET_LIST";
export const ARTICLES_GET_LIST_SUCCESS = "ARTICLES_GET_LIST_SUCCESS";
export const ARTICLES_GET_LIST_ERROR = "ARTICLES_GET_LIST_ERROR";
export const ARTICLES_GET_LIST_BY_TRACKING = "ARTICLES_GET_LIST_BY_TRACKING";
export const ARTICLES_GET_LIST_BY_TRACKING_SUCCESS =
  "ARTICLES_GET_LIST_BY_TRACKING_SUCCESS";
export const ARTICLES_GET_LIST_BY_TRACKING_ERROR =
  "ARTICLES_GET_LIST_BY_TRACKING_ERROR";
export const ARTICLES_GET_ONE = "ARTICLES_GET_ONE";
export const ARTICLES_GET_ONE_SUCCESS = "ARTICLES_GET_ONE_SUCCESS";
export const ARTICLES_GET_ONE_ERROR = "ARTICLES_GET_ONE_ERROR";

/* NEWS */
export const NEWS_LIST_GET_TOTALS = "NEWS_LIST_GET_TOTALS";
export const NEWS_LIST_GET_TOTALS_SUCCESS = "NEWS_LIST_GET_TOTALS_SUCCESS";
export const NEWS_LIST_GET_TOTALS_ERROR = "NEWS_LIST_GET_TOTALS_ERROR";
export const NEWS_LIST_GET_LIST = "NEWS_LIST_GET_LIST";
export const NEWS_LIST_GET_LIST_SUCCESS = "NEWS_LIST_GET_LIST_SUCCESS";
export const NEWS_LIST_GET_LIST_ERROR = "NEWS_LIST_GET_LIST_ERROR";
export const NEWS_LIST_GET_LIST_SEARCH = "NEWS_LIST_GET_LIST_SEARCH";
export const NEWS_LIST_GET_LIST_WITH_FILTER = "NEWS_LIST_GET_LIST_WITH_FILTER";
export const NEWS_LIST_GET_LIST_WITH_ORDER = "NEWS_LIST_GET_LIST_WITH_ORDER";
export const NEWS_LIST_GET_ONE = "NEWS_LIST_GET_ONE";
export const NEWS_LIST_GET_ONE_SUCCESS = "NEWS_LIST_GET_ONE_SUCCESS";
export const NEWS_LIST_GET_ONE_ERROR = "NEWS_LIST_GET_ONE_ERROR";
export const NEWS_LIST_GET_LIST_BY_TRACKING = "NEWS_LIST_GET_LIST_BY_TRACKING";
export const NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS =
  "NEWS_LIST_GET_LIST_BY_TRACKING_SUCCESS";
export const NEWS_LIST_GET_LIST_BY_TRACKING_ERROR =
  "NEWS_LIST_GET_LIST_BY_TRACKING_ERROR";

/* ADVS */
export const ADVS_LIST_GET_TOTALS = "ADVS_LIST_GET_TOTALS";
export const ADVS_LIST_GET_TOTALS_SUCCESS = "ADVS_LIST_GET_TOTALS_SUCCESS";
export const ADVS_LIST_GET_TOTALS_ERROR = "ADVS_LIST_GET_TOTALS_ERROR";
export const ADVS_LIST_GET_LIST = "ADVS_LIST_GET_LIST";
export const ADVS_LIST_GET_LIST_SUCCESS = "ADVS_LIST_GET_LIST_SUCCESS";
export const ADVS_LIST_GET_LIST_ERROR = "ADVS_LIST_GET_LIST_ERROR";
export const ADVS_LIST_GET_IMPORTANT = "ADVS_LIST_GET_IMPORTANT";
export const ADVS_LIST_GET_IMPORTANT_SUCCESS =
  "ADVS_LIST_GET_IMPORTANT_SUCCESS";
export const ADVS_LIST_GET_IMPORTANT_ERROR = "ADVS_LIST_GET_IMPORTANT_ERROR";
export const ADVS_LIST_GET_LIST_WITH_FILTER = "ADVS_LIST_GET_LIST_WITH_FILTER";
export const ADVS_LIST_GET_LIST_WITH_ORDER = "ADVS_LIST_GET_LIST_WITH_ORDER";
export const ADVS_LIST_GET_LIST_SEARCH = "ADVS_LIST_GET_LIST_SEARCH";
export const ADVS_LIST_GET_ONE = "ADVS_LIST_GET_ONE";
export const ADVS_LIST_GET_ONE_SUCCESS = "ADVS_LIST_GET_ONE_SUCCESS";
export const ADVS_LIST_GET_ONE_ERROR = "ADVS_LIST_GET_ONE_ERROR";
export const ADVS_LIST_GET_LIST_BY_TRACKING = "ADVS_LIST_GET_LIST_BY_TRACKING";
export const ADVS_LIST_GET_LIST_BY_TRACKING_SUCCESS =
  "ADVS_LIST_GET_LIST_BY_TRACKING_SUCCESS";
export const ADVS_LIST_GET_LIST_BY_TRACKING_ERROR =
  "ADVS_LIST_GET_LIST_BY_TRACKING_ERROR";

export const FILES_GET_LIST = "FILES_GET_LIST";
export const FILES_GET_LIST_SUCCESS = "FILES_GET_LIST_SUCCESS";
export const FILES_GET_LIST_ERROR = "FILES_GET_LIST_ERROR";

export const DEPARTMENTS_LIST_GET_LIST = "DEPARTMENTS_LIST_GET_LIST";
export const DEPARTMENTS_LIST_GET_LIST_SUCCESS =
  "DEPARTMENTS_LIST_GET_LIST_SUCCESS";
export const DEPARTMENTS_LIST_GET_LIST_ERROR =
  "DEPARTMENTS_LIST_GET_LIST_ERROR";
export const DEPARTMENTS_LIST_GET_ONE = "DEPARTMENTS_LIST_GET_ONE";
export const DEPARTMENTS_LIST_GET_ONE_SUCCESS =
  "DEPARTMENTS_LIST_GET_ONE_SUCCESS";
export const DEPARTMENTS_LIST_GET_ONE_ERROR = "DEPARTMENTS_LIST_GET_ONE_ERROR";
export const DEPARTMENTS_LIST_GET_LIST_SEARCH =
  "DEPARTMENTS_LIST_GET_LIST_SEARCH";

export const CONFERENCES_GET_LIST = "CONFERENCES_GET_LIST";
export const CONFERENCES_GET_LIST_SUCCESS = "CONFERENCES_GET_LIST_SUCCESS";
export const CONFERENCES_GET_LIST_ERROR = "CONFERENCES_GET_LIST_ERROR";
