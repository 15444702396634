import React from "react";
import BannerStyleFour from "../../components/Banner/BannerStyleFour";
import About from "./AboutSection";
import Advs from "./AdvsSection";
import AboutWork from "./AboutWorkSection";
import Counter from "./CounterSection";
import Team from "./TeamSection";
import Cta from "./CtaSection";
import Testimonial from "./TestimonialSection";
import News from "./NewsSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import Members from "./MembersSection";

const HomeFiveMain = ({ facultyDetails, members }) => {
  return (
    <React.Fragment>
      {/* BannerStyleFour-Start */}
      <BannerStyleFour faculty={facultyDetails} lang='en' />
      {/* BannerStyleFour-End */}

      {/* About-Start */}
      <About faculty={facultyDetails} />
      {/* About-End */}

      {/* Courses-Start */}
      <Advs faculty={facultyDetails} />
      {/* Courses-End */}
      <br />
      {/* About-Work-Start */}
      {/* <AboutWork /> */}
      {/* About-Work-End */}

      {/* Counter-Start */}
      <Counter faculty={facultyDetails} />
      {/* Counter-End */}

      {/* Team-Start */}
      {/* <Team /> */}
      {/* Team-End */}

      {/* Cta-Start */}
      {/* <Cta
				ctaClass="rs-cta"
				ctaTitle="Admission Open for 2020"
				ctaDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eius to mod tempor incididunt ut labore et dolore magna aliqua. Ut enims ad minim veniam. Aenean massa. Cum sociis natoque penatibus et magnis."
				btnText="Apply Now"
			/> */}
      {/* Cta-End */}

      {/* Testimonial-Start */}
      <Testimonial faculty={facultyDetails && facultyDetails.Url} />
      {/* Testimonial-End */}

      {/* Blog-Start */}
      <News faculty={facultyDetails} />
      {/* Blog-End */}

      {/* Members */}
      <Members members={members} />

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeFiveMain;
