import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";

// Team Members
import teamImg1 from "../../assets/img/team/av.png";
import teamImgf from "../../assets/img/team/avf.png";

const Members = ({ members }) => {
  const foundMembers = members && members.filter((m) => m.show === "true");
  console.log(foundMembers)
  return (
    <React.Fragment>
      <div
        id="rs-team"
        className="rs-team style1 inner-style orange-style pt-102 pb-110 md-pt-64 md-pb-70 gray-bg"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title mb-50 md-mb-30 text-center"
            subtitleClass="sub-title orange"
            subtitle="الادارة"
            titleClass="title mb-0"
            title="اعضاء هيئة التدريس بالكلية"
          />
          <div className="row rtl">
            {foundMembers &&
              foundMembers.slice(0, 9).map((member) => (
                <div className="col-lg-4 col-md-6 mb-30">
                  <SingleTeam
                    itemClass="team-item"
                    Image={member.sex === "انثى" ? teamImgf : teamImg1}
                    Title={member.name}
                    Designation={member.AcDegree}
                    Id={member._id}
                    department={member.department}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Members;
