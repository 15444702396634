import React ,{useEffect ,useState}from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Image
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

// Team Images

import { useDispatch, useSelector } from 'react-redux';
import { getMembersAllList, getMembersListSearch } from '../../redux/membersList/actions';

const TeamTwo = ({ match }) => {
    const [numberOfMembers , setNumberOfMembers] = useState(50)
    const dispatch = useDispatch();
   
    useEffect(() => {
     
        dispatch(getMembersAllList());          
      }, []);

      const { membersItems } = useSelector((state) => state.memberlistApp);

    return (
        <React.Fragment>
            <OffWrap />
            <Header
        parentMenu="blog"
        secondParentMenu="blogSidebar"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4 rtl"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA"
      />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
        pageTitle="أعضاء هيئة التدريس  بالجامعة"
        pageName="أعضاء هيئة التدريس "
        breadcrumbsImg={bannerbg}
        mainLink={ null}
        parentCategory={ "الرئيسية"}
      />
            {/* breadcrumb-area-End */}
              
               
            <div className="rs-team style1 inner-style orange-style pt-94 pb-70 md-pt-64 md-pb-40 gray-bg">
                <div className="container">
                   
                    {/* Section Title Start */}
                    <SectionTitle
                        sectionClass="sec-title mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title orange"
                        subtitle="اعضاء هيئة التدريس"
                        titleClass="title mb-0"
                        title="القارين بالجامعة"
                    />
                    {/* Section Title End */}
                    <div className="row rtl"  >
                  
                    <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder='البحث '
                    // defaultValue={searchKeyword}
                    onChange={(e) => {
                      dispatch(getMembersListSearch(e.target.value));
                    }}
                  />
                   
                    {membersItems && membersItems.slice(0,numberOfMembers).map(member => 
                       <div className="col-lg-4 col-md-6 mb-50">
                            <SingleTeamThree
                                itemClass="team-item"
                                Image={ `https://resumption.zu.edu.ly/${member.image}`}
                                Title={member.name}
                                Designation={member.AcDegree}
                                Id={member._id}
                                department={member.department}
                                sex={member.sex}
                            />
                        </div>   )}
                        
                       
                    </div>
                </div>
                <div className="rs-latest-events style1 bg-wrap  text-center">
            <div className="event-wrap">
              <div className="btn-part mt-54 md-mt-24">
                <a  onClick={()=> setNumberOfMembers(numberOfMembers + 50)} >المزيد ... </a>
              </div>
            </div>
          </div>
            </div>

            <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default TeamTwo