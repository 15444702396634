/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-underscore-dangle */
import {
  MEMBERS_LIST_GET_LIST,
  MEMBERS_LIST_GET_LIST_SUCCESS,
  MEMBERS_LIST_GET_LIST_ERROR,
  MEMBERS_LIST_GET_ONE,
  MEMBERS_LIST_GET_ONE_SUCCESS,
  MEMBERS_LIST_GET_ONE_ERROR,
  MEMBERS_LIST_SEARCH
} from "../contants";

const INIT_STATE = {
  allMembersItems: null,
  membersItems: null,
  member: null,
  error: null,
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  departments: null,

  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MEMBERS_LIST_GET_LIST:
    case MEMBERS_LIST_GET_ONE:
      return { ...state, loading: false };

    case MEMBERS_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allMembersItems: action.payload.data,
        membersItems: action.payload.data,
      };

    case MEMBERS_LIST_GET_ONE_SUCCESS:
      return {
        ...state,
        loading: true,
        member: action.payload.data,
      };

      case MEMBERS_LIST_SEARCH:
        if (action.payload === '') {
          return { ...state, membersItems: state.allMembersItems };
        }
        // eslint-disable-next-line no-case-declarations
        const keyword = action.payload.toLowerCase();
        // eslint-disable-next-line no-case-declarations
        const searchItems = state.allMembersItems.filter(
          (item) =>
            (item.name && item.name.toLowerCase().indexOf(keyword) > -1) ||
            (item.nid && item.nid.toString().indexOf(keyword) > -1)
        );
        return {
          ...state,
          loading: true,
          membersItems: searchItems,
          searchKeyword: action.payload,
        };

    case MEMBERS_LIST_GET_LIST_ERROR:
    case MEMBERS_LIST_GET_ONE_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
