// eslint-disable-next-line import/no-cycle
import {
  MEMBERS_LIST_GET_ALL_LIST,
  MEMBERS_LIST_GET_LIST,
  MEMBERS_LIST_GET_LIST_SUCCESS,
  MEMBERS_LIST_GET_LIST_ERROR,
  MEMBERS_LIST_GET_ONE,
  MEMBERS_LIST_GET_ONE_SUCCESS,
  MEMBERS_LIST_GET_ONE_ERROR,
  MEMBERS_LIST_GET_LIST_BY_FACULTY,
  MEMBERS_LIST_SEARCH
} from "../contants";


export const getMembersAllList = () => ({
  type: MEMBERS_LIST_GET_ALL_LIST,
});

export const getMembersListByFaculty = (url) => ({
  type: MEMBERS_LIST_GET_LIST_BY_FACULTY,
  payload: url,
});

export const getMembersListSearch = (keyword) => ({
  type: MEMBERS_LIST_SEARCH,
  payload: keyword,
});

export const getMembersList = (url) => ({
  type: MEMBERS_LIST_GET_LIST,
  payload: url,
});

export const getMembersListSuccess = (items) => ({
  type: MEMBERS_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getMembersListError = (error) => ({
  type: MEMBERS_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getOneMember = (id) => ({
  type: MEMBERS_LIST_GET_ONE,
  payload: id,
});

export const getOneMemberSuccess = (item) => ({
  type: MEMBERS_LIST_GET_ONE_SUCCESS,
  payload: item,
});

export const getOneMemberError = (error) => ({
  type: MEMBERS_LIST_GET_ONE_ERROR,
  payload: error,
});
