import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Tilty from "react-tilty";
import AOS from "aos";

import wave from "../../assets/img/banner/banner-line.png";
import circle from "../../assets/img/banner/banner-circle.png";
import dots from "../../assets/img/banner/banner-dots.png";
import { Url } from "../../constants/defaultValues";

const BannerStyleFour = ({ faculty ,lang ,rrr}) => {
  useEffect(() => {
    AOS.init();
  });
  const bgStyle = {
    backgroundImage: `url(${faculty && Url + faculty.image})`,
    "background-size": "cover",
    "background-position": "center",
    height: "100vh",
    display: "grid",
    "align-items": "center",
  };
   console.log(lang ,rrr)
  return faculty &&  (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style4" style={bgStyle}>
        <div className="container relative">
          <div className="row relative">
            <div className="col-lg-10 offset-lg-2">
              <div className="banner-line-shape">
                <Tilty perspective={100}>
                  <img
                    src={wave}
                    alt=""
                    data-aos="flip-left"
                    data-aos-duration="3000"
                  />
                </Tilty>
              </div>
              
              <div className="banner-content pb-14 rtl">
                <h1
                  className="banner-title white-color"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  { lang === 'en' ? faculty.enFacultyTitle : faculty.facultyTitle}
                </h1>
                <div
                  className="desc white-color mb-40 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <h2 className=" white-color">
                    {lang === 'en' ? 'University Of Zawia' : 'جامعة الزاوية'}
                      </h2>
                </div>
                {lang ? '' :<div
                  className="banner-btn z-index-1"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  <Link
                    className="readon2 banner-style"
                    to={`/faculties/${faculty && faculty.Url}/about`}
                  >
                    إقرأ المزيد
                  </Link>
                </div>}
              </div>
              <div className="banner-circle-shape">
                <Tilty>
                  <img className="rotateme" src={circle} alt="" />
                </Tilty>
              </div>
            </div>
          </div>
          <div className="banner-dots-shape">
            <Tilty>
              <img src={dots} alt="" />
            </Tilty>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  )
};

export default BannerStyleFour;
