import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getDepartmentsList } from "../../../redux/departmentList/actions";
import { getFacultiesList } from "../../../redux/facultiesList/actions";

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
  faculty,
  facultyId,
  lang
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacultiesList());
  }, []);
  useEffect(() => {
    facultyId && dispatch(getDepartmentsList(facultyId));
  }, [facultyId]);
  const { facultiesItems } = useSelector((state) => state.facultiesListApp);
  const { departmentsItems } = useSelector((state) => state.departmentListApp);

  const [Faculties, setFaculties] = useState(false);
  const [offices, setOffices] = useState(false);
  const [about, setAbout] = useState(false);
  const [course, setCourse] = useState(false);
  const [pages, setPages] = useState(false);
  const [team, setTeam] = useState(false);
  const [event, setEvent] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [shop, setShop] = useState(false);
  const [otherPages, setOtherPages] = useState(false);
  const [blog, setBlog] = useState(false);
  const [blogSidebar, setBlogSidebar] = useState(false);
  const [blogSingle, setBlogSingle] = useState(false);
  const [contact, setContact] = useState(false);
  const [research, setResrearch] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "Faculties") {
      setFaculties(!Faculties);
      setAbout(false);
      setOffices(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "offices") {
      setFaculties(false);
      setOffices(!offices);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "about") {
      setFaculties(false);
      setAbout(!about);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "course") {
      setFaculties(false);
      setAbout(false);
      setCourse(!course);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "pages") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(!pages);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "team") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(!team);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "event") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(!event);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "gallery") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(!gallery);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "shop") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(!shop);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "otherPages") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(!otherPages);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blog") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(!blog);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blogSidebar") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(true);
      setBlogSidebar(!blogSidebar);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blogSingle") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(true);
      setBlogSidebar(false);
      setBlogSingle(!blogSingle);
      setContact(false);
    } else if (menu === "contact") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(!contact);
      setResrearch(false)
    }else if (menu === "research") {
      setFaculties(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
      setResrearch(!research)
    }
  };

  const medicalFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "3");
  const mainSinceFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "1");
  const humanFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "2");
  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            {faculty ? (
              <li
                className={parentMenu === "about" ? " current-menu-item" : ""}
              >
                <Link
                  to={`/faculties/${facultyId}/about`}
                  className={
                    location.pathname === `/faculties/${facultyId}/about`
                      ? "active-menu"
                      : ""
                  }
                >
                  عن الكلية
                </Link>
              </li>
            ) : 
            <>{lang === 'en' ? '': (
              <li
                className={parentMenu === "about" ? " current-menu-item" : ""}
              >
                <Link
                  to="/about-university"
                  className={
                    location.pathname === "/about-university"
                      ? "active-menu"
                      : ""
                  }
                >
                  عن الجامعة
                </Link>
              </li>
            )}
            </>
            } 
            {/* **** faculties Start *** */}
            {!faculty && (
              <li
                className={
                  pages
                    ? "menu-item-has-children current-menu-item"
                    : "menu-item-has-children"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    openMobileMenu("pages");
                  }}
                  className={parentMenu === "pages" ? "active-menu" : ""}
                >
                  {lang === 'en' ? 'Faculties' : 'الكليات'}
                </Link>
                <ul className={pages ? "sub-menu current-menu" : "sub-menu"}>
                  <li
                    className={
                      team
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("team");
                      }}
                      className={
                        secondParentMenu === "team" ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Basic and applied sciences' : 'العلوم الاساسية و التطبيقية'}
                    </Link>
                    <ul className={team ? "sub-menu current-menu" : "sub-menu"}>
                      {mainSinceFaculty &&
                        mainSinceFaculty.map((faculty) => (
                          <li>
                            <Link
                              to={`/faculties/${faculty.Url}`}
                              className={
                                location.pathname ===
                                `/faculties/${faculty.Url}`
                                  ? "active-menu"
                                  : ""
                              }
                            >
                             { lang === 'en' ?  faculty.enFacultyTitle : faculty.facultyTitle}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li
                    className={
                      shop
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("shop");
                      }}
                      className={
                        secondParentMenu === "faculty" ? "active-menu" : ""
                      }
                    >
                     {lang === 'en' ? 'humanities' : 'العلوم الانسانية'}
                    </Link>
                    <ul className={shop ? "sub-menu current-menu" : "sub-menu"}>
                      {humanFaculty &&
                        humanFaculty.map((faculty) => (
                          <li>
                            <Link
                              to={`/faculties/${faculty.Url}`}
                              className={
                                location.pathname ===
                                `/faculties/${faculty.Url}`
                                  ? "active-menu"
                                  : ""
                              }
                            >
                                                       { lang === 'en' ?  faculty.enFacultyTitle : faculty.facultyTitle}

                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li
                    className={
                      gallery
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("gallery");
                      }}
                      className={
                        secondParentMenu === "faculty" ? "active-menu" : ""
                      }
                    >
                     {lang === 'en' ? 'Medical and paramedical' : 'الطبية و الطبية المساعدة'}
                    </Link>
                    <ul
                      className={gallery ? "sub-menu current-menu" : "sub-menu"}
                    >
                      {medicalFaculty &&
                        medicalFaculty.map((faculty) => (
                          <li>
                            <Link
                              to={`/faculties/${faculty.Url}`}
                              className={
                                location.pathname ===
                                `/faculties/${faculty.Url}`
                                  ? "active-menu"
                                  : ""
                              }
                            >
                                 { lang === 'en' ?  faculty.enFacultyTitle : faculty.facultyTitle}

                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                </ul>
              </li>
            )}
            {/* ***** faculty End *** */}
            {/* **** Officies Start *** */}
            {/* <li
              className={
                offices
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("offices");
                }}
                className={parentMenu === "offices" ? "active-menu" : ""}
              >
                الهيكل التنظيمي
              </Link>
              <ul className={offices ? "sub-menu current-menu" : "sub-menu"}>
                {facultiesItems &&
                  facultiesItems.map((faculty) => (
                    <li>
                      <Link
                        to={`/faculty/${faculty.Url}`}
                        className={
                          location.pathname === `/faculty/${faculty.Url}`
                            ? "active-menu"
                            : ""
                        }
                      >
                        {faculty.facultyTitle}
                      </Link>
                    </li>
                  ))}
              </ul>
            </li> */}
            {/* ***** Officies End *** */}

            {!faculty && (
              <li
                className={
                  blog
                    ? "menu-item-has-children current-menu-item"
                    : "menu-item-has-children"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    openMobileMenu("blog");
                  }}
                  className={parentMenu === "blog" ? "active-menu" : ""}
                >
                 {lang === 'en' ? 'Organizational Structure' : 'الهيكل  التنظيمي'}
                </Link>
                <ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
                <li
                    className={
                      blogSingle
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("blogSingle");
                      }}
                      className={
                        secondParentMenu === "blogSingle" ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Offices' : 'المكاتب'}
                    </Link>
                    <ul
                      className={
                        blogSingle ? "sub-menu current-menu" : "sub-menu"
                      }
                    >
                      <li>
                      <a
                      target="_blank"
                      href="https://ico.zu.edu.ly/"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'International Cooperation' : 'التعاون الدولي'}
                    </a>
                      </li>
                      <li>
                      <a
                      target="_blank"
                      href="https://offices.zu.edu.ly/ucoa"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
مكتب شؤون مجلس الجامعة                      </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      blogSidebar
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("blogSidebar");
                      }}
                      className={
                        secondParentMenu === "blogSidebar" ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Centers' : 'المراكز'}{" "}
                    </Link>
                    <ul
                      className={
                        blogSidebar ? "sub-menu current-menu" : "sub-menu"
                      }
                    >
                      <li>

                      <a
                      target="_blank"
                      href="https://rcc.zu.edu.ly/"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Scientific Research and Consulting Center' : 'مركز البحوث والاستشارات العلمية'}
                    </a>
                       
                      </li>
                      <li>

                        <a
                        target="_blank"
                        href="https://elc.zu.edu.ly/"
                             className={
                          location.pathname === `/offices` ? "active-menu" : ""
                        }
                        >
                      {lang === 'en' ? 'E-Learning Center' : 'مركز التعليم الإلكتروني'}
                        </a>
                        
                        </li>
                      <li>
                      <a
                      target="_blank"
                      href="https://offices.zu.edu.ly/acprcm"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Plant Research and Complementary Medicine' : 'المركز المتقدم لأبحاث النبات والطب التكميلي'}
                   </a>
                      </li>
                      
                    </ul>
                  </li>
                  <li
                    className={
                      blogSingle
                        ? "menu-item-has-children current-menu-item"
                        : "menu-item-has-children"
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        openMobileMenu("blogSingle");
                      }}
                      className={
                        secondParentMenu === "blogSingle" ? "active-menu" : ""
                      }
                    >
                    {lang === 'en' ? 'Administrations' : 'الادارات'}

                    </Link>
                    <ul
                      className={
                        blogSingle ? "sub-menu current-menu" : "sub-menu"
                      }
                    >
                      {/* <li>
                        <Link
                          to="/"
                          className={
                            location.pathname === "/blog/post-left-sidebar"
                              ? "active-menu"
                              : ""
                          }
                        >
                          ادارة
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </li>
            )}
            {/* ****** البرنامج الاكاديمي  */}
            {/* <li className={parentMenu === "about" ? " current-menu-item" : ""}>
              <Link
                to="/programs"
                className={
                  location.pathname === "/programs" ? "active-menu" : ""
                }
              >
                البرامج الاكاديمية
              </Link>
            </li> */}
            {/* ****** نهاية البرنامج الاكاديمي   */}

            {/* ************************* */}
            {/* ******اقسام الكلية******** */}
            {faculty && (
              <li
                className={
                  contact
                    ? "menu-item-has-children current-menu-item"
                    : "menu-item-has-children"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    openMobileMenu("contact");
                  }}
                  className={parentMenu === "contact" ? "active-menu" : ""}
                >
                  اقسام الكلية
                </Link>
                <ul className={contact ? "sub-menu current-menu" : "sub-menu"}>
                  {departmentsItems &&
                    departmentsItems.map((department) => (
                      <li>
                        <Link
                          to={`/department/${department.Url}`}
                          className={
                            location.pathname === "/contact"
                              ? "active-menu"
                              : ""
                          }
                        >
                          {department.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            <li
              className={
                parentMenu === "acprograms" ? " current-menu-item" : ""
              }
            >
              <Link
                to="/acprograms"
                className={
                  location.pathname === "/acprograms" ? "active-menu" : ""
                }
              >
             {lang === 'en' ? 'Ac Programs' : ' البرامج الاكاديمية'}
              </Link>
            </li>
            <li
                className={
                  research
                    ? "menu-item-has-children current-menu-item"
                    : "menu-item-has-children"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    openMobileMenu("research");
                  }}
                  className={parentMenu === "research" ? "active-menu" : ""}
                >
                   {lang === 'en' ? 'Sc research' : 'البحث العلمي'}
                </Link>
                <ul className={research ? "sub-menu current-menu" : "sub-menu"}>
                 
                  <li>
                      <a
                        href="https://scholar.google.com/citations?hl=en&view_op=search_authors&mauthors=zu.edu.ly&btnG=&fbclid=IwAR2oPREiw7g_9vwlUd2Hzhf0FQy_ATPFpb7dKrFCVCsv7smf8fB_ZSZ9acU"
                        className={
                          location.pathname === "/department" ? "active-menu" : ""
                        }
                      >
                        {" "}
                        {lang === 'en' ? 'Scientific Researcher' : 'الباحث العلمي'}
                      </a>
                  </li>
                  <li>
                    <a
                  href="https://journals.zu.edu.ly/"
                  className={
                    location.pathname === "/department"
                      ? "active-menu nav-link"
                      : "nav-link"
                  }
                >
                  {" "}
                  {lang === 'en' ? 'University scientific journals platform' : 'منصة المجلات العلمية بالجامعة'}
                </a></li>
                      <li><a
                  href="https://dspace.zu.edu.ly"
                  className={
                    location.pathname === "/department"
                      ? "active-menu nav-link"
                      : "nav-link"
                  }
                >
                  {" "}
                  {lang === 'en' ? 'Digital Repository' : 'المستودع الرقمي'}

                </a></li>
                   
                </ul>
              </li>


            {/* ******اقسام الكلية النهاية******** */}
            {!faculty ? (
              <li
                className={parentMenu === "about" ? " current-menu-item" : ""}
              >
                <Link
                  to="/forms-evidence"
                  className={
                    location.pathname === "/forms-evidence" ? "active-menu" : ""
                  }
                >
                  {lang === 'en' ? 'Evidence' : 'الادلة و النماذج'}
                </Link>
              </li>
            ) : (
              <li
                className={parentMenu === "about" ? " current-menu-item" : ""}
              >
                <Link
                  to={`/faculties/${facultyId}/forms-evidence`}
                  className={
                    location.pathname === "/forms-evidence" ? "active-menu" : ""
                  }
                >
                  الادلة و النماذج
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
